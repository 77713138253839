@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body,
#root {
  height: 100%;
  font-family: "Inter", sans-serif;
  font-weight: 300; /* For thinner text */
}

.swiper-pagination-bullet {
  background: #3aada8 !important; /* Replace with your primary color */
}

.swiper-pagination-bullet-active {
  background: #3aada8 !important; /* Darker shade or another color for active state */
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.animate-fadeInUp {
  animation: fadeInUp 0.8s ease-out;
}

.loader {
  border: 4px solid #f3f3f3;
  border-radius: 50%;
  border-top: 4px solid #3aada8; /* Matches primaryColor */
  width: 24px;
  height: 24px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
